<template>
	
	<div
		ref="vselect"
		:id="id"
		class="vselect"
		:class="{
			'vselect-multi' : multiple,
		}"
	>

		<!-- Liste déroulante -->
		<select
			v-if="!multiple"
			ref="select_tag"
			:name="name"
			@change="change_event"
		>
			<option
				v-for="( option, key ) in options" 
				v-bind:key="key" 
				:value="option.value"
			>{{ option.label }}</option>
		</select>

		<!-- Liste multiple -->
		<select
			v-if="multiple"
			v-model="values"
			ref="select_tag"
			:name="name"
			@change="change_event"
			:multiple="multiple"
		>
			<option 
				v-for="( option, key ) in options" 
				v-bind:key="key" 
				:value="option.value"
				:selected="selected_option.value == option.value ? 'selected' : ''"
			>{{ option.label }}</option>
		</select>

		<div ref="container" class="vselect-container" :class="{ open : open }">

			<div class="vselect-selector" @click="( !multiple ) ? toggle() : false">

				<div v-if="!multiple" class="selected" :class="{ placeholder : ( !selected_option ) }">
					<div>{{ ( selected_option ) ? selected_option.label : placeholder }}</div>
				</div>
				<i v-if="!multiple"></i>

				<div v-if="multiple" class="selected" :class="{ placeholder : ( !selected_option ) }">
					
					<div>
						<div
							class="vselected-option"
							v-for="( option, key ) in selected_options"
							v-bind:key="key"
							:data-value="option.value"
							@click="multiple_remove_option( option )"
						>
							<span>{{ option.label }}</span>
							<button type="button"></button>
						</div>
					</div>

				</div>
				<button v-if="multiple" class="vselect_multiple-btn" type="button" @click="toggle()">
				</button>

			</div>

			<div
				ref="options"
				:id="'voptions-' + id"
				class="vselect-options"
			>
			
				<div class="options-overlay" v-if="is_phone()" @click="toggle( false )" />
				
				<div ref="options_container">

					<div
						class="vselect-option"
						v-if="placeholder"
						:class="{ selected : ( selected_option && selected_option.value == 'null' ) }"
						@click="selected={ value : '', label : placeholder }"
					>{{ placeholder }}</div>

					<div
						class="vselect-option"
						v-for="( option, key ) in options"
						v-bind:key="key"
						:data-value="option.value"
						:class="{ selected : ( values.includes( option.value ) ) }"
						@click="select_option( $event, option )"
					>{{ option.label }}</div>

				</div>
			
			</div>

		</div>

	</div>

</template>

<script>

	import device from '@/mixins/device'

	export default {

		name: 'vselect',

		components	: {
		},

		props	: [

			'id',	// ID
			'name',	// Attribut name
			'options',	// Toutes les options
			'select',		// Valeur à sélectionner
			'placeholder',	// Texte par défaut
			'onchange',	// Action au changement de valeur
			'multiple',	// Sélection multiple activée

		],

		data	: () => { return {

			// value_single	: false,	// Valeur sélectionnée
			values	: [],
			open	: false,	// Liste déroulante des options ouverte
			selected_option	: false,	// Objet sélectionné
			selected_options	: [],	// Objets 'option' sélectionnées (multiple)

		}},

		mixins	: [ device ],

		methods	: {

			toggle( value ) {

				var body	= document.body
				var app	= document.getElementById( 'app' )

				if ( typeof( value ) != 'undefined' ) {
					this.open	= value
				} else {
					this.open	= !this.open
				}

				if ( this.is_phone() ) {

					if ( this.open ) {

						body.classList.add( 'vselect-open' )

						app.append( this.$refs.options )

					} else {

						body.classList.remove( 'vselect-open' )

						document.getElementById( 'voptions-' + this.id ).remove()

					}

				}

			},

			/**
			 */

			select_option( $event, option ) {

				if ( !this.multiple ) {	// Single value
					
					// this.open	= false
					this.toggle( false )

					this.$refs.select_tag.value	= option.value
					this.selected_option	= option
					this.values	= [ option.value ]
					this.$refs.select_tag.dispatchEvent( new Event( 'change' ) )

				} else {	// Multiple values

					if ( !$event.target.classList.contains( 'selected' ) ) {

						this.selected_options.push( option )
						this.values.push( option.value )

					} else {
						this.multiple_remove_option( option )
					}

					this.multiple_update_select_values()

				}

			},

			/**
			 */

			manual_select_option( value, not_trigger_change ) {

				/**
				 * Valeur unique
				 */

				if ( !this.multiple ) {

					var selecteds	= this.options.filter( options => (
						options.value == value
					))

					if ( selecteds.length == 0 ) {
						return false
					}

					this.$refs.select_tag.value	= value
					this.selected_option	= selecteds[0]
					this.values	= [ selecteds[0].value ]

					if ( !not_trigger_change ) {
						this.$refs.select_tag.dispatchEvent( new Event( 'change' ) )
					}

				}
				
				/**
				 * Valeurs multiples (à faire)
				 */

			},

			/**
			 */

			change_event() {

				if ( this.onchange && typeof this.onchange == 'function' ) {
					this.onchange( this.selected_option )
				}
				
			},

			/**
			 * Select multiple
			 * 
			 * Suppresion d'une valeur sélectionnée
			 */

			multiple_remove_option( option ) {

				this.values	= this.values.filter( value => value != option.value )
				this.selected_options	= this.selected_options.filter( selected => selected.value != option.value )

				this.multiple_update_select_values()

			},

			/**
			 * Select multiple
			 * 
			 * Mise à jour des valeurs sélectionnées dans le tag HTML
			 * + Trigger
			 */

			multiple_update_select_values() {

				var _this	= this

				Array.from( _this.$refs.select_tag.options ).forEach(( option ) => {

					if ( _this.values.includes( option.value ) ) {
						option.selected	= true
					} else {
						option.selected	= false
					}

				})

				_this.$refs.select_tag.values	= _this.values

				_this.$refs.select_tag.dispatchEvent( new Event( 'change' ) )

			},

			/**
			 * (re)calcul des dimensions du conteneur
			 */

			window_resize() {

				this.$refs.select_tag.removeAttribute( 'style' )
				this.$refs.select_tag.style	= 'display:none'

			},

		},

		computed	: {

			get_selected_option	: function() {
				return this.selected_option
			},

		},

		watch	: {

			selected_option	: function( selected ) {

				if ( !selected ) {
					return false
				}

				this.$refs.select_tag.value	= selected.value

			}

		},

		mounted	: function() {

			var _this	= this

			if ( this.select ) {

				/**
				 * Valeur simple
				 */

				if ( !this.multiple ) {

					this.selected_option	= this.options.find( option => option.value == this.select )

					if ( this.selected_option ) {

						this.values.push( this.select )

						this.$refs.select_tag.value	= this.select
						this.$refs.select_tag.dispatchEvent( new Event( 'change' ) )

					}

				}
				
				/**
				 * Valeurs multiples
				 */

				else {

					_this.select.forEach(( value ) => {
						_this.selected_options.push( _this.options.find( option => option.value == value ) )
					})

					_this.values	= _this.select
					_this.multiple_update_select_values()

				}

			}

			this.window_resize()
			window.onresize	= this.window_resize

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/components/vselect';

</style>